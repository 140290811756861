body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /* font-family: 'Inter', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input.p-column-filter{
  border: 1px solid gray;
}

/* index.css */

/* Thin scrollbar with gray background and blue-700 thumb */
::-webkit-scrollbar {
  width: 8px; /* Width of vertical scrollbar */
  height: 8px; /* Height of horizontal scrollbar */
}

/* Track (background) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray background */
  border-radius: 4px; /* Rounded corners */
}

/* Handle (thumb) */
::-webkit-scrollbar-thumb {
  background: #1d4ed8; /* Tailwind's blue-700 */
  border-radius: 4px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e40af; /* Darker blue on hover */
}

.form-input {
  display: block;
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 1.5;
  color: #1f2937;
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  appearance: none;
}

.form-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
}

.form-input::placeholder {
  color: #9ca3af;
}

.form-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
}