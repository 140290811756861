.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Base button styles */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Button variants */
.btn-accent {
  background-color: #172554; /* blue-950 */
  color: white;
}

.btn-accent:hover:not(:disabled) {
  background-color: #1e3a8a; /* blue-900 */
}

.btn-accent:focus {
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

.btn-success {
  background-color: #16a34a; /* green-600 */
  color: white;
}

.btn-success:hover:not(:disabled) {
  background-color: #15803d; /* green-700 */
}

.btn-success:focus {
  box-shadow: 0 0 0 3px rgba(22, 163, 74, 0.5);
}

.btn-danger {
  background-color: #dc2626; /* red-600 */
  color: white;
}

.btn-danger:hover:not(:disabled) {
  background-color: #b91c1c; /* red-700 */
}

.btn-danger:focus {
  box-shadow: 0 0 0 3px rgba(220, 38, 38, 0.5);
}

.btn-gray {
  background-color: #4b5563; /* gray-600 */
  color: white;
}

.btn-gray:hover:not(:disabled) {
  background-color: #374151; /* gray-700 */
}

.btn-gray:focus {
  box-shadow: 0 0 0 3px rgba(75, 85, 99, 0.5);
}

/* Loading state */
.btn.loading {
  position: relative;
  color: transparent;
}

.btn.loading::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: currentColor;
  border-bottom-color: currentColor;
  animation: button-loading-spinner 0.75s infinite linear;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

/* For buttons with icons */
.btn svg, 
.btn img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
